.home {
  color: #fff;
}

.textFields {
  margin: 12px 3px !important;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.actionButton button {
  border-radius: 20px;
}

.flex {
  display: flex;
}

.coverPhoto img {
  width: 100%;
  height: 350px;
  position: fixed;
  margin-left: -23px;
  margin-top: -16px;
}

.profilePic {
  position: absolute;
  top: 45%;
  display: flex;
}

.profilePic svg {
  font-size: 8.1875rem;
}

.profileName {
  margin-top: 75px;
  color: #1f5254;
}

.pulse {
  margin-top: 100px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #1f9a09;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: pulse 2s infinite;
  display: flex;
  margin-left: 88px;
  position: absolute;
}

.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@media only screen and (max-width: 600px) and (min-width: 320px)  {
  .textFields {
    margin: 5px 3px !important;
  }

 }

.coverphotoContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 200px;
  margin-bottom: 40px;
  padding-inline: 20px;
  background-color: #67abbf;
  background-image: url(../../assets/images/coverPhoto.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.coverphotoContainer span {
  background-color: #fff;
  width: 96px;
  position: absolute;
  display: block;
  border: 1px solid #67abbf;
  border-radius: 5px;
  padding: 5px;
  top: 100%;
  transform: translateY(-50%);
  text-align: center;
}

.profilePictureContainer svg {
  color: #67abbf;
  font-size: 6rem;
}

.paperContainer {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
  padding: 10px;
}

.listItem {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  font-size: .875rem
}

.listItemText {
  flex-grow: 1;
}

.listItemText span {
  font-weight: 900;
}

.ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

@media (min-width: 600px) {
  .coverphotoContainer {
    height: 250px;
    padding-inline: 50px;
  }

  .profilePictureContainer svg {
    font-size: 7rem;
  }

  .coverphotoContainer span {
    width: 112px;
  }

  .paperContainer {
    padding: 20px;
  }
}

@media (min-width: 900px) {
  .coverphotoContainer {
    height: 300px;
  }

  .profilePictureContainer svg {
    font-size: 10rem;
  }

  .coverphotoContainer span {
    width: 160px;
  }

  .paperContainer {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 100px;
  }
}