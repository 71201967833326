.mainContainer {
  max-width: none !important;
  max-height: 1080px !important;
  height: 100% !important;
  background-color: #072853 !important
}

.errorContentContainer {
  height: 100%;
  padding: 0;
  display: grid !important;
  grid-template-columns: 1fr;
  gap: 20px
}

.errorMessageContainer {
  place-self: start center;
}

.errorTitle {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: .8;
  color: #fd7923;;
}

.errorSubtitle {
  font-size: 2.75rem;
  font-weight: 900;
  letter-spacing: 5px;
  text-align: center;
  color: #fd7923;;
}

.errorImage {
  place-self: end center;
  width: 200px;
  height: 200px;
}

@media (min-width: 768px) {
  .errorContentContainer {
    gap: 30px
  }

  .errorMessageContainer {
    place-self: start center;
  }

  .errorTitle {
    margin-bottom: 10px;
    font-size: 2.5rem;
  }
  
  .errorSubtitle {
    font-size: 5rem;
  }

  .errorImage {
    place-self: end center;
    width: 300px;
    height: 300px;
  }
}