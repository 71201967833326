.mainBoxWorkflow {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}

.mainBoxHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
}

.mainBoxHeaderTitle {
  font-size: 28px !important;
  font-weight: 600 !important;
}

.triggerFormSubmitButton {
  align-self: flex-end;
  width: fit-content;
  border-radius: 30px !important;
}

.tableHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
}

.tableHeader > button {
  color: #000;
}

.tableHeaderTitle {
  font-size: 21px !important;
  font-weight: 500 !important;
}

.tableColumnTitle th {
  font-weight: 600;
  padding-block: 8px;
}

.tableBody td {
  padding-block: 3px;
}

.actionCell {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

.tableBody tr:hover .actionButtonGroup {
  opacity: 1;
}

.actionButtonGroup {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.triggerForm {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}

.triggerForm input {
  padding-block: 16px;
}

.triggerForm label {
  font-size: 14px;
}

.textfield {
  margin: 12px 0 !important;
  width: 95%;
}

.button {
  float: right;
  margin-bottom: 5px;
  border-radius: 20% !important;
}

.card {
  background-color: #f5f5f5 !important;
  height: 98%;
  padding: 10px;
}

.textHeight {
  width: 20% !important;
}

.error {
  color: red !important;
  margin: 0 !important;
}

.intervalSelect {
  width: 45%;
  height: 40px;
  left: 1em;
  right: 1em;
}

.taskIntervalSelect {
  width: 40%;
  height: 40px;
  margin: 0 10px;
}

.taskIntervalType {
  width: 30%;
  height: 40px;
}

.formControl {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.typographyMargin {
  padding-left: 10px;
  font-weight: bold !important;
  color: #000000bf;
}

.triggerSelect {
  width: 95% !important;
  height: 40px;
  margin: 9px;
}

.startTimeText {
  height: 40px;
  right: 15px;
}

.duration {
  height: 40px;
  width: 15%;
}

.durationType {
  width: 43%;
  height: 40px;
  margin-right: 14px;
}

.table {
  min-width: 700px;
}

.taskTitleDue {
  margin-top: 10px;
  margin-left: 10px;
}

.appbar {
  background-color: #006064;
}

.taskSubButton {
  color: #263238;
  margin-left: 10px;
}

.taskPrioritySelect {
  width: 95% !important;
}

.taskInterval {
  width: 20%;
}

.textBold {
  font-weight: bold;
}

.triggerBody {
  display: flex;
}

.triggerBody .triggerLabel {
  padding: 5px 15px;
}

.boldMargin {
  font-weight: bold;
  margin-top: 2rem;
}

.label {
  display: flex;
  align-content: center;
}

.title {
  flex-grow: 1;
  font-weight: initial;
  margin-left: 10px;
  text-transform: capitalize;
}

.cell {
  height: 20px;
  padding: 10px;
  align-items: center;
  margin: 15px;
  text-transform: capitalize;
}

.tableTitle {
  display: flex;
  justify-content: space-between;
}

.tileBody {
  display: flex;
  align-items: center;
}

thead tr th {
  border-top: 1px solid #ef8345;
  background-color: #f5f4f4 !important;
  border-bottom: 2px solid #9e9a9a !important;
}

.eventDue {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
}

.actionButton {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.actionButton button {
  border-radius: 20px;
}

.taskformControl {
  display: flex;
  align-items: center;
}

.workflowformControl {
  margin: 0 12px 30px;
}


@media only screen and (max-width: 600px)  {   

  .startTimeText {
    right: 0;
  }

  .durationType {
    margin-right: 0;
  }

}