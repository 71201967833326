.table {
  min-width: 700px;
}

.appbar {
  background-color: #006064;
}

.title {
  flex-grow: 1;
  font-weight: initial;
  margin-left: 10px;
  text-transform: capitalize;
}

.head {
  display: flex;
}

.rightAction {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header {
  display: flex;
  align-items: center;
}

.taskTransaction {
  display: flex;
  align-items: center;
  height: 20px;
  padding: 10px;
  margin: 15px;
  text-transform: capitalize;
}

.itemMargin {
  margin-left: 2;
}

.cell {
  height: 20px;
  padding: 10px;
  align-items: center;
  margin: 15px;
  text-transform: capitalize;
}

.emailCell {
  height: 20px;
  padding: 10px;
  align-items: center;
  margin: 15px;
}

.capitalizedCell {
  height: 20px;
  padding: 10px;
  align-items: center;
  margin: 15px;
  text-transform: capitalize;
}

.actions {
  width: 100px !important;
}

.tableTitle {
  display: flex;
  justify-content: space-between;
}

.tileBody {
  display: flex;
  align-items: center;
  margin-top: 15px;

}

.downTitle {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

thead tr th {
  border-top: 1px solid #236064;
  background-color: #f5f4f4 !important;
  border-bottom: 2px solid #9e9a9a !important;
}

.amount {
  height: 20px;
  padding: 10px;
  align-items: center;
  margin: 15px;
  text-transform: capitalize;
  text-align: right !important;
}

.taskLegend {
  display: flex;
  align-items: center;
  padding: 15px;
}

.box {
  width: 20px;
  height: 20px;
  background: blue;
}

.circle {
  width: 10px;
  height: 8px;
  border-radius: 50%;
  background: #236064;
  margin-right: 14px;
  margin-bottom: 4px;
  margin-left: 10px;
}

.tableDesc {
  white-space: nowrap;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.searchBar {
  display: flex;
  align-items: center;
}

.groupTileSearch {
  display: flex;
  width: 70%;
  align-items: inherit;
  justify-content: space-between;
}

.searchBody {
  /* width: 630px; */
  margin-top: 5px;
}

.searchList {
  display: flex;
}

.advancedSearch {
  width: 220px;
  background: #ef8354 !important;
}

.searchField {
  margin-right: 10px !important;
}

.duration {
  width: 50px !important;
}

.filedAt {
  width: 90px;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.showButtonTasksBody button {
  border-radius: 50px;
}

.link {
  color: black;
  text-decoration: none;
}

.link:hover {
  color: #006064;
}

.toggleButton {
  border-radius: 50px !important;
  background-color: #236064 !important;
  color: white !important;
  margin-left: 12px !important;
}

.checkColor {
  color: #1f9a09;
}

.clearColor {
  color: #ff0404;
}

/* Hoverabletablerow */

tbody tr:nth-child(odd) {
  background-color: #cccccc1a;
}

.tableBody tr:hover {
  cursor: pointer;
}

.actionButtonHoverable {
  position: absolute;
  min-width: 65px;
  right: 0px !important;
  border-bottom: none !important;
  opacity: 0;
  z-index: 10;
}

.actionButtonHoverable td {
  border-bottom: none !important;
}

.actionButtonHoverable:hover {
  opacity: 1;
}

.tableBody tr:hover .actionButtonHoverable {
  opacity: 1;
  transition: opacity 1s;
}

.fullWitdActionHoverable {
  position: absolute;
  width: 100%;
  right: 0 !important;
  border-bottom: none !important;
  opacity: 0;
  z-index: 10;
}

.fullWitdActionHoverable:hover {
  opacity: 1;
}

.tableBody tr:hover .fullWitdActionHoverable {
  opacity: 1;
  transition: opacity 1s;
}

.fullWitdActionHoverable td {
  border-bottom: none !important;
}

.notLinkable td:last-child {
  pointer-events: visible !important;
  cursor: default;
  text-align: right;
}

.linkable td:last-child {
  pointer-events: visible !important;
  cursor: default;
  text-align: right;
}

td.cellCase {
  pointer-events: visible !important;
}

.buttonDisbursement {
  width: 60px;
  display: flex;
  justify-content: space-between;
}

.linkable {
  pointer-events: auto !important;
}

tr.fullWitdActionHoverable:hover {
  opacity: 1;
  transition: opacity 1s;
}

.invoice {
  width: 300px !important;
}

.tablePagination {
  width: 320px;
}

.cellEmail {
  text-transform: lowercase !important;
}

.cellRef {
  width: 140px !important;
}

.groupSearchButton {
  display: contents;
}

@media only screen and (max-width: 600px) {

  .searchList {
    flex-direction: column;
  }

  .groupSearchButton {
    display: flex;
    margin-top: 16px;
    justify-content: flex-end;
  }

  .downTitle {
    position: absolute;
    top: 215px;
    display: flex;
    align-items: flex-end;
  }

  .tablePagination {
    margin-top: -70px;
  }

}
