.button {
    display: none !important;
}

.parent{
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.parent:hover .button{
    display: block !important;
}

.index{
    padding: 0 !important
}

.textfield {
    max-width: 100%;
    padding-inline-start: 20px !important;
}

.emptyBody {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    font-weight: bold;
    font-style: italic;
}