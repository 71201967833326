.title {
  font-size: 30px;
  font-weight: 500;
  margin-left: 20px;
  text-transform: capitalize;
}

.flex {
  display: flex;
}

.tabBody {
  border-bottom: 2px solid #ef8354;
  margin-right: 25px;
}

.tabBody .tabList {
  text-transform: none !important;
  min-width: 100px;
  flex-direction: initial !important;
}

.tabList svg {
  color: #4f5d75;
}

.fieldBody {
  display: flex;
  align-items: baseline;
}

.cell{
  height: 20px;
  padding: 10px;
  align-items: center;
  margin: 15px;
  text-transform: capitalize;
}

.customCell {
  border: none;
  border-bottom: none !important;
  padding: 0;
  width: 130px;
}

.customCellEmail {
  border: none;
  border-bottom: none !important;
  padding: 0;
}

.customTdCell {
  padding: 0;
}

.customTdCell td {
  border-bottom: none !important;
}

.tabListTitle {
  display: flex;
  align-items: center;
}

.PrivateTabIndicator-colorSecondary-14 {
  background-color: #0a6065;
}

.LeftBody {
  width: 100%;
}

.billerAutocomplete .biller {
  width: 400px !important;
}

.rightBody {
  width: 35%;
  position: absolute;
  right: 18px;
  top: 238px;
  z-index: 10;
}

.tableHeadBody tr td {
  border-top: 1px solid #236064;
  background-color: #f5f4f4 !important;
  border-bottom: 2px solid #9e9a9a !important;
  padding: 10px;
}

.listBody thead tr th {
  padding: 5px;
}

.listBody tbody tr td {
  padding: 5px;
}

button.expandedButton[aria-expanded='true'] {
  transform: scale(1) rotate(180deg);
  transition-delay: revert;
  color: #e21818;
}

button.expandedButton {
  color: #e21818;
}

.paper {
  color: rgba(0, 0, 0, 0.54);
  padding: 16px;
}

.taskBody {
  padding: 0;
}

.rightlistBody {
  padding: 20px;
  background: #f1f1f1;
  box-shadow: -4px 6px 6px -6px black;
}

.fieldTitle {
  font-size: 17px;
  font-weight: 600;
  margin: 6px;
  width: 250px;
  display: flex;
  justify-content: space-between;

}

.fieldTitle span {
  margin-right: 40px;
}

.fieldValue {
  font-size: 17px;
}

.caseInfoBody {
  margin-top: 20px;
}

.avatarSize {
  width: 70px !important;
  height: 70px !important;
}

.iconSize {
  width: 50px !important;
  height: 50px !important;
}

.name {
  font-size: 30px;
  margin-left: 20px;
}

.listAvatarName {
  display: flex;
  align-items: baseline;
  padding: 0 15px;
}

.contactTitle {
  font-size: 20px;
  margin: 10px;
  display: flex;
  align-items: flex-end;
}

.clientBodyDetails {
  padding: 10px;
}

.folderHeader {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #236064;
  border-bottom: 2px solid #5555559c;
  font-size: 18px;
  font-weight: 700;
  padding: 6px 10px;
  margin: 0 0 8px 0;
  background-color: #f5f4f4 !important;
}

.folderAction {
  width: 120px;
  text-align: end;
  margin-right: 10px;
}

.legalDocumentBody {
  padding-left: 20px;
}

.clientBodyDetails svg {
  margin-right: 10px;
  font-size: 30px;
  color: #4f5d75;
}

.listBody {
  /* display: flex; */
  margin: 15px;
}

.fieldClientTitle {
  align-items: flex-end;
  font-size: 15px;
  font-weight: 600;
  display: flex;
}

.clientDetailsValue {
  margin: 10px 10px 10px 50px;
  font-size: 17px;
}

.subtitle {
  display: flex;
  margin-left: 10px;
  align-items: center;
}

.subtitle div {
  margin: 0 11px;
  font-size: 16px;
  color: #000000a1;
}

.button {
  float: right;
}

.text {
  color: #fff;
}

.caseCourtbutton {
  padding: 0 !important;
  font-size: inherit !important;
}

.closeButton {
  width: 0 !important;
}

.tableTitle {
  display: flex;
  justify-content: space-between;
}

.formTextfield {
  margin: 12px 3px !important;
}

.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

.MuiAccordionSummary-root {
  height: 0 !important;
}

.sub_2 {
  padding-left: 25px;
}

.sub_3 {
  padding-left: 50px;
}

.sub_4 {
  padding-left: 75px;
}

.sub_5 {
  padding-left: 120px;
}

.Parent .sub_2 {
  display: flex !important;
  align-items: flex-end !important;
}

.Parent .childOf_2 {
  display: flex !important;
  align-items: flex-end !important;
}

.Parent .childOf_3 {
  display: flex !important;
  align-items: flex-end !important;
}

.Parent .childOf_4 {
  display: flex !important;
  align-items: flex-end !important;
}

.Parent .folderIcon {
  margin-right: 5px !important;
}

.Parent .sub_3 {
  display: flex !important;
  align-items: center !important;
}

.Parent .sub_4 {
  display: flex !important;
  align-items: center !important;
}

.Parent .sub_5 {
  display: flex !important;
  align-items: center !important;
}

.actionButton button {
  border-radius: 20px !important;
  margin: 10px;
}

.dialog-title {
  background-color: #2d3142;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.dialog-title h2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.dialog-title h2 button {
  color: #fff;
  font-weight: 800;
}

.sub_doc_2 {
  padding-left: 30px;
}

.sub_doc_3 {
  padding-left: 60px;
}

.sub_doc_4 {
  padding-left: 90px;
}

.accordion-body .MuiAccordionSummary-root {
  height: auto !important;
}

.Parent {
  margin: 0 !important;
}

.parent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.child {
  padding-left: 0 !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.child_2 {
  padding-left: 50px !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.child_3 {
  padding-left: 75px !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.child_4 {
  padding-left: 130px !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 52px;
}

.form-textfield {
  margin: 12px 3px !important;
}

.dateBody {
  display: flex;
  justify-content: space-between;
}

.dateBody input {
  width: 400px;
}

.Bodylinear {
  padding: 0;
  margin-top: -24px;
  margin-left: -25px;
  width: 105%;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #2f634b;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.caeseformBody .actionButton button {
  border-radius: 20px !important;
  margin: 10px;
}

.actionSearchButton {
  display: flex;
  justify-content: flex-end;
}

.actionSearchButton .search {
  border-radius: 20px !important;
  margin: 0 10px;
}

.MuiButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: transparent !important;
}

.flexbodyDate .formTextfield {
  width: 50% !important;
}

.flexbodyDate {
  display: flex;
  margin-bottom: 15px;
}

.practiceAreaBody {
  display: grid;
  grid-template-columns: 8fr 1fr;
}

.actionAddButton {
  display: flex !important;
  margin-top: 10px !important;
  margin-left: auto !important;
}

.showClientName {
  display: flex;
  font-size: 14px;
  color: #0000008c;
}

.contactPerson-body {
  padding: 7px 30px !important;
}

.listAvatarNameShowClient {
  display: flex;
  align-items: flex-end;
  padding: 10px 16px;
}

.secondary {
  font-size: 18px !important;
  font-weight: 900 !important;
  color: #fff !important;
  background: #2d3142 !important;
  height: 27px !important;
  width: 29px !important;
  display: flex !important;
  align-items: center !important;
}

.secondary:hover {
  background-color: #ef8354 !important;
}

.clientShowBodyLegalCase .NameDatails {
  padding: 10px 0 !important;
}

.LegalCase_LegalCase_clientShowBodyLegalCase__2yKrl__1NbHQ .LegalCase_Client_NameDatails__3JBh-__2p9i3 {
  padding: 15px 0 !important;
}

.Client_NameDatails__3JBh- {
  padding: 14px;
}

.clientShowBodyLegalCase .parent-label {
  font-size: 21px;
}

.clientShowBodyLegalCase .clientShowBodyLegalCaseList button {
  display: none;
}

.contactPersonName {
  margin-top: 10px;
}

#panel1a-header .MuiAccordionSummary-content {
  margin: 0 !important;
  margin-top: 10px !important;
}

.headerTitleBody {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.headerTitleBody .editButton {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}

.billerSub {
  margin-left: 19px;
  margin-top: -12px;
}

.flexbodyDate div {
  margin: 0 5px 0 0 !important;
}

.titles {
  font-size: 2rem;
  text-align: center !important;
  margin-top: 10%;
  color: #4aa1f3;
  font-weight: bold;
}

.content {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

form .input {
  position: relative;
  opacity: 0;
  z-index: 99;
  height: 200px;
}

form .filelabel {
  text-align: center;
  margin-top: -109px;
}

.formBody {
  height: 200px !important;
  border: dashed !important;
  display: grid;
  margin-bottom: 20px;
}

.chooseWork {
  height: 40px;
  margin: 5px 0;
}

.workFlowDate {
  margin-top: 5px;
}

form {
  margin-bottom: 10px;
}

.subDate {
  margin: 10px;
  font-size: 16px;
}

.subName {
  font-size: 18px;
}

.fileError {
  color: red;
}

.documentBody {
  display: flex;
  justify-content: space-between;
}

.actionDelete {
  width: 82px;
}

.refField {
  width: 89% !important;
  margin-right: 10px;
}

.userField {
  width: 425px !important;
  margin-right: 15px;
  margin-bottom: 10px;
}

.practiceField {
  /* width: 420px !important; */
  margin-bottom: 12px;
}

.clientField {
  width: 425px !important;
  margin-right: 12px;
}
.billerField{
  width: 425px !important;
  margin-right: 12px;
}

.billerField input {
  width: 385px !important;
}

.error {
  color: red;
  margin-top: -10px;
}

.folderTitle {
  display: flex;
}

.folderTitle svg {
  margin-right: 10px;
}

.documentTitle svg {
  margin-right: 10px;
}

.tileBody {
  display: flex;
  align-items: center;
}

.tabLabel {
  margin-left: 10px;
  font-size: 17px;
  font-weight: 600;
}

.headerTitleBody .groupButton button:last-child {
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.fieldLabel {
  width: 150px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.fieldLabel span {
  margin-right: 10px;
}

/* .historyIcon {
  margin-left: 260px;
} */

.button.expandedButton[aria-expanded='false'] {
  transform: scale(1.3) rotate(180deg);
  transition-delay: revert;
}

.caseTitle {
  display: flex;
}

.contactPersonTitle {
  font-size: 20px;
  margin: 10px;
  display: flex;
  align-items: flex-end;
}

.emailTitle {
  font-size: 20px;
  margin: 10px;
  display: flex;
  align-items: flex-end;
}

.addressTitle {
  font-size: 20px;
  margin: 10px;
  display: flex;
  align-items: flex-end;
}

.cursor {
  font-style: italic;
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
}

.cursor:hover {
  color: rgb(8, 8, 160);
}

.asterisk {
  color: rgb(190, 39, 39) !important;
  font-size: 20px !important;
  margin-left: 5px !important;

}

.doneState {
  padding: 0 14px !important;
  background: rgb(107, 201, 80) !important;
  color: #fff !important;
  font-weight: 700 !important;
}

.pendingState {
  padding: 0 14px !important;
  background: #b4b4b4 !important;
  color: #fff !important;
  font-weight: 700 !important;
}

.activeState {
  padding: 0 14px !important;
  background: #3f51b5 !important;
  color: #fff !important;
  font-weight: 700 !important;
}

.optionName {
  text-transform: capitalize;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.toggleButton {
  position: absolute;
  right: 18px;
  top: 21%;
  bottom: 77%;
}

.boxMain {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}

@media only screen and (max-width: 600px)  {

  .toggleButton {
    position: absolute;
    top: 220px;
    right: auto;
  }
  .rightBody {
    width: 100%;
    position: unset;
  }

  .subtitle {
    display: contents;
    margin-left: -10px;
  }

  .buttonGroup {
    margin-left: -20px;
  }

  .fieldBody {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
  }

  .fieldTitle {
    width: 180px;
  }

  .fieldBody1 {
    display: block !important;
  }

  .fieldBody1 .fieldTitle {
    margin-left: 30px;
  }

  .fieldBody1 .refValue {
    margin-left: 30px;
  }

  .fieldBodyCus {
    display: block;
  }

  .fieldBodyCus .refValue {
    margin-left: 30px;
  }

}
