.button {
  float: right;
  background-color: #2d3142;
}

.dialog-wrapper {
  position: center;
}

.header {
  background-color: #2d3142;
  color: #fff;
  padding-right: 0;
}

.secondary {
  font-size: 18px !important;
  font-weight: 900 !important;
  color: #fff !important;
  background: #2d3142 !important;
  height: 27px !important;
  width: 29px !important;
  display: flex !important;
  align-items: center !important;
}

.secondary:hover {
  background-color: #ef8345 !important;
}
