.mainContainer {
  max-width: none !important;
  max-height: 1080px !important;
  height: 100% !important;
  background-color: #072853 !important
}

.errorContentContainer {
  height: 100%;
  padding: 0;
  display: grid !important;
  grid-template-columns: 1fr;
  gap: 20px
}

.errorHeaderContainer {
  align-self: end;
  display: grid;
  grid-template-columns: 1fr;
}

.errorMessageContainer {
  place-self: start center;
}

.errorTitle {
  font-size: 2.75rem;
  font-weight: 900;
  text-align: center;
  line-height: .8;
  color: #fff;;
}

.errorSubtitle {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 5px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;;
}

.errorImage {
  place-self: end center;
  width: 250px;
  height: 250px;
}

.errorBodyContainer {
  text-align: center;
}

.errorBodyContainer > p {
  margin: 0;
  font-size: .875rem;
  color: #fff
}

.errorBodyContainer > p:last-child {
  font-weight: 100;
}

.errorBodyContainer > p:last-child span {
  font-weight: 800;
}

@media (min-width: 768px) {
  .errorContentContainer {
    gap: 0
  }

  .errorHeaderContainer {
    align-self: end;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px
  }

  .errorMessageContainer {
    place-self: end start;
    padding-bottom: 60px;
  }

  .errorTitle {
    font-size: 6.5rem;
    font-weight: 300;
    text-align: left;
  }
  
  .errorSubtitle {
    font-size: 1.75rem;
    text-align: left;
    letter-spacing: 2px;
  }

  .errorImage {
    place-self: end;
    width: 350px;
    height: 350px;
  }

  .errorBodyContainer > p:first-child {
    margin-bottom: 30px;
    font-size: 1.5rem;
    font-weight: 100;
  }
}