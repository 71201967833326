@import '../../CssGlobalVariables/globalVariables.css';

.table {
  min-width: 700px;
}

.appbar {
  background-color: #006064;
}

.title {
  flex-grow: 1;
  font-weight: initial;
  margin-left: 10px;
  text-transform: capitalize;
}

.cell {
  height: 20px;
  align-items: center;
  margin: 15px;
  text-transform: capitalize;
}

.overDueCell {
  height: 20px;
  align-items: center;
  margin: 15px;
  text-transform: capitalize;
  color: red !important;
}

.overDueTableDesc {
  white-space: nowrap;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: red !important;
}

.linkCell {
  height: 20px;
  align-items: center;
  text-transform: capitalize;
}

.overDueLinkCell {
  height: 20px;
  align-items: center;
  text-transform: capitalize;
  color: red !important;
}

.tableTitle {
  display: flex;
  justify-content: space-between;
}

.tileBody {
  display: flex;
  align-items: center;
  margin-top: 13px;
}

thead tr th {
  border-top: 1px solid #236064;
  background-color: #f5f4f4 !important;
  border-bottom: 2px solid #9e9a9a !important;
}

.unreadCell {
  height: 20px;
  align-items: center;
  margin: 15px;
  text-transform: capitalize;
  font-weight: bold !important;
}

.stateButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.checkButton {
  background-color: #236064;
}

.formTextfield {
  margin: 12px 5px !important;
  text-transform: capitalize;
}

.taskName {
  font-size: 25px !important;
  padding: 0 15px;
  font-weight: revert;
  color: #565555;
}

.taskDescription {
  padding: 0 15px;
  font-size: 16px;
}

.taskBodyPriotyDate {
  display: flex;
}

.taskBodyPriotyDate .taskListPriority {
  width: 50%;
}

.taskBodyPriotyDate .taskListDuedate {
  width: 50%;
}

.taskPriority {
  padding-left: 15px;
  text-transform: capitalize;
  font-size: 18px !important;
  font-weight: 500;
  color: #575555;
}

.taskDuedate {
  padding-left: 15px;
  text-transform: capitalize;
  font-size: 18px !important;
  font-weight: 500;
  color: #575555;
}

.taskAvatarIcon {
  display: flex;
  align-items: baseline;
}

.taskAvatarBody {
  margin: 10px;
  display: block !important;
}

.taskAvatarName {
  margin-left: 10px !important;
  font-size: 18px !important;
  font-weight: 500;
}

.personAssign {
  margin-top: 40px;
}

.taskHrLineButton {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.taskLabel {
  margin-top: 15px !important;
}

.taskLine {
  width: 136px;
  border-bottom: 3px solid #6abdad;
  margin-bottom: 10px;
}

.taskLine::before {
  content: '';
}

.taskHrLine {
  border: 0;
  height: 2px;
  background-image: linear-gradient(267deg, #053e33, #b4e4db);
  width: 131px;
  margin-left: inherit;
  margin-top: 0 !important;
  margin-bottom: 15px;
}

.taskActionButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px !important;
}

.taskActionButton button {
  border-radius: 20px;
  margin: 10px;
}

.noUserAssign {
  margin-left: 15px;
  margin-top: 10px;
  font-size: 16px;
}

.taskFormGroup {
  display: flex;
  align-items: baseline;
}

.unreadTableDesc {
  white-space: nowrap;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold !important;
}

.tableDesc {
  white-space: nowrap;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.addTaskBody {
  display: flex;
  align-items: center;
}

.addTaskBody p {
  color: #383838;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.actionButton button {
  border-radius: 20px;
  font-weight: bold;
  text-transform: capitalize;
}

.showButtonTasksBody button {
  border-radius: 20px;
  font-weight: bold;
  border-style: double;
}

.circle {
  width: 10px;
  height: 8px;
  border-radius: 50%;
  background: #236064;
  margin-right: 14px;
  margin-bottom: 4px;
  margin-left: 10px;
}

.itemMargin {
  margin-left: 2px;
}

.header {
  display: flex;
  align-items: center;
}

.workflowLabel {
  display: flex !important;
  align-items: center !important;
  margin-right: 10px !important;
}

.error {
  color: red;
  margin: 0;
}

.switch {
  float: right;
}

.colorRed td {
  color: #ff0404 !important;
}

.searchList {
  display: flex;
  align-items: flex-end;
}

.searchBody {
  width: 630px;
  margin-top: 5px;
}

.advancedSearch {
  /* width: 220px; */
  background: #ef8354 !important;
}

.searchField {
  margin-right: 10px !important;
}

.searchBar {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
}

.actionSearchButton {
  display: flex;
  justify-content: flex-end;
}

.actionSearchButton button {
  border-radius: 20px;
}

.taskTitle {
  border-left: 3px solid var(--fontColor);
  margin-bottom: 20px;
}

.taskTitle .taskTitleList {
  margin-left: 10px;
}

.taskDescBody:hover::before {
  position: absolute;
  content: "Description";
  background: var(--fontColor);
  color: #fff;
  opacity: 1;
  transition: right 1s ease 0s;
  width: 60px;
  padding: 3px;
  left: 38px;
  font-size: 10px;
  top: 135px;
}

.taskDesc {
  margin-left: 15px;
}

.taskTitle:hover::before {
  position: absolute;
  content: "Task";
  background: var(--fontColor);
  color: #fff;
  opacity: 1;
  transition: right 1s ease 0s;
  width: 35px;
  padding: 3px;
  font-size: 10px;
}

.taskLink {
  padding: 0 15px;
  font-size: 18px !important;
  font-weight: 500;
  color: #575555 !important;
  margin-top: 15px;
}

.taskLink a {
  text-decoration: none;
  font-size: 22px;
  color: var(--fontColor);
  font-weight: 700;
}

.taskUserBody {
  display: flex;
  flex-wrap: wrap-reverse;
}

.taskUserBody .taskUserList {
  margin-right: 10px;
}

.taskLabelShared {
  margin: 10px;
}

.actionButtonHoverable {
  position: absolute;
  min-width: 65px;
  right: 10px !important;
  margin-top: 4px !important;
  border-bottom: none !important;
  opacity: 0;
  z-index: 10;
}

.actionButtonHoverable:hover {
  opacity: 1;
}

.tableBody tr:hover td.actionButtonHoverable {
  opacity: 1 !important;
  transition: opacity 1s !important;
}

.asterisk {
  color: rgb(150, 20, 20);
  font-size: 20px;
}

.groupSearchButton {
  display: flex;
}

.boxHeader {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 5px;
}

.boxHeaderChild {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
}

.boxHeaderSearch {
  flex-grow: 1;
}

.boxHeaderActions {
  flex-grow: 1;
}

.showHideTaskButton {
  font-weight: 600 !important;
  border-radius: 50px !important;
}

.boxHeaderChildSm {
  justify-content: flex-end;
}

.buttonGroupTasks button {
  font-size: 10px;
}

.boxMain {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}

@media (min-width: 700px) {
  .boxHeader {
    flex-direction: row;
  }

}

.taskInvoiceTableTitle {
  font-size: 19px !important;
  font-weight: 600 !important;
}

.taskInvoiceFilterBox {
  margin-bottom: 10px !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  gap: 8px !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.taskInvoiceForm {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  align-items: center !important;
  gap: 5px !important;
}

.tableRowHead > * {
  padding-block: 4px !important;
  font-weight: 800 !important;
}

.tableRowBody {
  cursor: pointer !important;
  background-color: initial !important;
  transition: background-color 200ms ease-in !important;
}

.tableRowBody:hover {
  background-color: rgb(239, 239, 239) !important;
}

.tableRowBody > * {
  padding-block: 2px !important;
}

.taskInvoiceMainBox {
  min-height: 500px;
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
  gap: 8px !important;
}

.taskInvoiceTable {
  flex-grow: 1 !important;
  max-height: 450px !important;
  overflow: auto !important;
}

.boxInvoiceDate {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: flex-end !important;
  align-items: center !important;
  gap: 8px !important;
}

.formContainer {
  flex-grow: 1 !important;
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
  gap: 8px !important;
  margin-bottom: 0 !important;
}