@import '../../CssGlobalVariables/globalVariables.css';

.table {
  min-width: 700px;
}

.appbar {
  background-color: #006064;
}

.cell {
  height: 20px;
  padding: 10px;
  align-items: center;
  margin: 15px;
  text-transform: capitalize;
}

.tableTitle {
  display: flex;
  justify-content: space-between;
}

.tileBody {
  display: flex;
  align-items: center;
}

thead tr th {
  border-top: 1px solid #236064;
  background-color: #f5f4f4 !important;
  border-bottom: 2px solid #9e9a9a !important;
}

.unreadCell {
  height: 20px;
  padding: 10px;
  align-items: center;
  margin: 15px;
  text-transform: capitalize;
  font-weight: bold;
}

.actionCell {
  height: 20px;
  padding: 10px;
  align-items: center;
  margin: 15px;
  text-transform: capitalize;
}

.stateButton {
  display: flex;
  padding-right: 60px;
}

.checkButton {
  background-color: #236064;
}

.calendarListBody {
  width: 85%;
}


.titleBody {
  margin-top: 60px;
  color: #236064;
  margin-left: 20px;
}

.boxNone {
  width: 15px;
  height: 15px;
  margin: 5px 20px;
  border: 1px solid #8fa598;
}

.boxLow {
  width: 15px;
  height: 15px;
  background-color: #ffd600;
  margin: 5px 20px;
  border: 1px solid #8fa598;
}

.boxMedium {
  width: 15px;
  height: 15px;
  background-color: #f57c00;
  margin: 5px 20px;
  border: 1px solid #8fa598;
}

.boxHigh {
  width: 15px;
  height: 15px;
  background-color: #ff0404;
  margin: 5px 20px;
  border: 1px solid #8fa598;
}

.none {
  display: flex;
  align-items: center;
  text-transform: capitalize !important;
}

.low {
  display: flex;
  align-items: center;
}

.medium {
  display: flex;
  align-items: center;
}

.high {
  display: flex;
  align-items: center;
}

.boxEvent {
  width: 15px;
  height: 15px;
  background-color: #236064;
  margin: 5px 20px;
  border: 1px solid #8fa598;
}

.event {
  display: flex;
  align-items: center;
}

.formTextfield {
  margin: 12px 0 !important;
}

.eventName {
  font-size: 35px !important;
  padding: 0 15px;
  font-weight: revert;
  color: #565555;
}

.eventDescription {
  padding: 0 15px;
  font-size: 16px;
}

.eventLink {
  padding: 0 15px;
  font-size: 18px !important;
  font-weight: 500;
  color: #575555 !important;
  margin-top: 15px;
}

.eventBodyPriotyDate {
  display: flex;
}

.eventBodyPriotyDate .eventListPriority {
  width: 50%;
}

.eventBodyPriotyDate .eventListDuedate {
  width: 50%;
}

.eventPriority {
  padding-left: 15px;
  text-transform: capitalize;
  font-size: 18px !important;
  font-weight: 500;
  color: #575555;
}

.eventDuedate {
  text-transform: capitalize;
  font-size: 16px !important;
  font-weight: 500;
  color: #575555;
}

.eventAvatarIcon {
  display: flex;
  align-items: baseline;
}

.eventAvatarBody {
  margin: 10px;
}

.eventAvatarName {
  margin-left: 10px !important;
  font-size: 18px !important;
  font-weight: 500;
}

.eventLabel {
  margin-top: 15px !important;
}

.eventActionButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px !important;
}

.eventActionButton button {
  margin: 10px;
  border-radius: 20px;
}

.eventFormGroup {
  display: flex;
  align-items: baseline;
}

.eventNoUser {
  font-size: 16px !important;
  margin-left: 15px;
  margin-top: 10px;
}

.dateBody {
  display: flex;
  justify-content: space-between;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.actionButton button {
  border-radius: 20px;
}

.toggleButton {
  border-radius: 50px;
  margin-left: 3em;
  background-color: #236064;
  color: white;
}

.eventTitle {
  border-left: 3px solid var(--fontColor);
  margin-bottom: 20px;
}

.eventTitle .eventTitleList {
  margin-left: 10px;
}

.eventDesc {
  margin-left: 15px;
}

.eventTitle:hover::before {
  position: absolute;
  content: "Event";
  background: var(--fontColor);
  color: #fff;
  opacity: 1;
  transition: right 1s ease 0s;
  width: 35px;
  padding: 3px;
  font-size: 10px;
}

.eventDescBody:hover::before {
  position: absolute;
  content: "Description";
  background: var(--fontColor);
  color: #fff;
  opacity: 1;
  transition: right 1s ease 0s;
  width: 60px;
  padding: 3px;
  left: 38px;
  font-size: 10px;
  top: 135px;
}

.eventLink a {
  text-decoration: none;
  font-size: 22px;
  color: var(--fontColor);
  font-weight: 700;
}

.eventLine {
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
  margin: 10px 0;
}

.eventUserBody {
  display: flex;
  flex-wrap: wrap-reverse;
}

.eventUserBody .eventUserList {
  margin-right: 10px;
  margin-bottom: 10px;
}

.eventLabelShared {
  margin: 10px;
}

.eventHrLineButton {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.dateRight{
  margin-right: 10px !important;
}

.boxMain {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}

@media only screen and (max-width: 600px) and (min-width: 320px)  {
  .titleBody {
    margin-top: 0px !important;
  }

  .legendBody {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

}
