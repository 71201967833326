.textfield {
    margin: 10px 5px 0 0 !important;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.actionButton button {
  border-radius: 20px;
  margin-top: 20px;
}

.textfield label {
  text-transform: capitalize;
}

.actionButtonHoverable {
  position: absolute;
  min-width: 65px;
  right: -7px !important;
  border-bottom: none !important;
  opacity: 0;
  z-index: 10;
}

.actionButtonHoverable td {
  border-bottom: none !important;
}

.actionButtonHoverable:hover {
  opacity: 1;
}

.tableBody tr:hover .actionButtonHoverable {
  opacity: 1;
  transition: opacity 1s;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  font-size: 19px;
  align-items: center;
  background: #3f51b5;
  color: aliceblue;
  font-weight: 600;
  padding: 0 10px;
  margin-top: 10px;
}

.cell {
  height: 20px;
  padding: 10px;
  align-items: center;
  margin: 15px;
  text-transform: capitalize;
}

.none {
  display: none;
}

.customCell {
  width: 200px;
  height: 20px;
  padding: 10px;
  align-items: center;
  margin: 15px;
  text-transform: capitalize;
}