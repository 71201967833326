.home {
  color: #fff;
}

.fc-button-group button {
  background-color: #ef8354 !important;
  border: 1px solid #c3c9ca !important;
}

.fc-col-header tbody tr th {
  border-top: 1px solid #ef8354 !important;
  background-color: #f5f4f4 !important;
  border-bottom: 1px solid #9e9a9a !important;
}

button.fc-today-button.fc-button.fc-button-primary {
  text-transform: capitalize;
}

button.fc-button.fc-button-active.fc-button-primary.fc-dayGridMonth-button {
  text-transform: capitalize;
}

button.fc-button.fc-button-primary.fc-timeGridDay-button {
  text-transform: capitalize;
}

button.fc-button.fc-button-primary.fc-timeGridWeek-button {
  text-transform: capitalize;
}

@media only screen and (max-width: 600px) and (min-width: 320px)  { 
  .fc-view-harness {
    height: 430px !important;
  }

  .fc-header-toolbar {
    flex-direction: column !important;
  }
  
}