.home {
  color: #fff;
}

.homeReport .Bodylinear {
  width: 150% !important;
}

.homeReportBody {
  margin-right: 20px;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

.home-doc {
  padding: 12px 20px;
}

.homeTask-body {
  margin-top: 20px;
}

.home-doc-count {
  padding: 45px 0 45px;
}

.homeTask {
  margin-top: 20px;
}

.dueTodayBorder {
  border-left: 12px solid rgb(243, 5, 5);
}

.startTodayBorder {
  border-left: 12px solid rgb(8, 235, 219);
}

.ongoingBorder {
  border-left: 12px solid rgb(52, 4, 224);
}

.eventBorder {
  border-left: 12px solid rgb(4, 134, 62);
}

.homeCasesLinks {
  text-decoration: none;
  color: #4f5d75;
  font-weight: 600;
}

.homeLine {
  border-width: thin;
  margin: 0;
}

.caseList {
  margin: 5px 15px;
}

.homeDesc {
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.homeLink {
  justify-content: flex-end;
  margin: 0 30px;
}

.homeLink a {
  text-decoration: none;
  color: #4f5d75;
  font-weight: 400;
}

.homeCasesfooter {
  display: flex;
  align-items: center;
}

.homeCasesfooter .list {
  display: flex;
  align-items: center;
}

.homeCasesfooter div:last-child {
  margin-left: 5px;
}

.eventFoot {
  display: flex;
  align-items: center;
}

.eventFoot div:last-child {
  margin-left: 5px;
}

.eventBody {
  margin-left: 15px;
}

.eventList {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.eventContents {
  padding: 5px 15px;
}

.colorRed {
  color: red !important;
  font-size: 15px !important;
}

.colorNone {
  font-size: 15px !important;
}

.titleButton {
  display: flex;
  justify-content: space-between;
}

.buttonList {
  width: 240px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.homeTaskContent {
  padding: 5px 10px;
}

.taskList {
  margin-left: 24px;
}

.titleAndIcon {
  display: flex;
  justify-content: space-between;
}

.titleButton h6 {
  max-width: 300px;
}

.calendarBox {
  box-shadow: 0 1px 2px 1px #00000069;
}

.test {
  background: red;
}

.eventsBody {
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  min-height: 347px;
  max-height: 347px;
}

.tasksBody {
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  min-height: 347px;
  max-height: 347px;
}

.userContent {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
  transform: translateY(-2em);
}

.userBody:hover .userContent {
  display: block;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
  padding: 5px 20px;
}

.headerTitle {
  display: flex;
  align-items: center;
}

.headerTitle svg {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 600px) and (min-width: 320px)  {
  .tasksBody {
    max-height: 100% !important;
  }
  
  .eventsBody {
    max-height: 100% !important;
  }

  .titleButton {
    flex-direction: column;
  }

  .buttonList {
    flex-direction: column;
    align-items: flex-start;
  }

  .titleAndIcon {
    flex-direction: column;
  }
  
  .homeCasesfooter {
    flex-direction: column;
    align-items: baseline;
  }

}