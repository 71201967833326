.home {
  color: #fff;
}

.textFields {
  margin: 12px 0 !important;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.actionButton button {
  border-radius: 20px !important;
}

.actionButton a {
  text-decoration: none;
}

.actionButton button:first-child {
  margin-right: 10px;
}

.error {
  color: red !important;
  margin: 0 !important;
  margin-top: -8px !important;
}

.formTextfield {
  margin-bottom: 10px;
}

.autocomplete {
  margin: 10px 0;
}

.modelBody {
  border: 1px solid #9f9f9f;
  margin: 10px 0;
  position: relative;
  border-radius: 4px;
}

.parentLabel {
  margin-left: 2px !important;
  margin-right: 0 !important;
}

.formFieldBody {
  height: 270px;
  overflow: auto;
}

.formFieldBody::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.formFieldBody::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
