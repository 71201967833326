@import './CssGlobalVariables/globalVariables.css';

.root {
  display: flex !important;
}

.appBar {
  z-index: 1201 !important;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
  background: #2d3142 !important;
}

.appBarShift {
  width: calc(100% - 260px) !important;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
  margin-left: 240px !important;
}

.menuButton {
  margin-right: 36px !important;
}

.hide {
  display: none !important;
}

.drawer {
  flex-shrink: 0 !important;
  white-space: nowrap !important;
}

.drawer.drawerOpen {
  width: 260px !important;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
}

.toolbar {
  display: flex !important;
  padding: 0 15px !important;
  min-height: 56px !important;
  align-items: center !important;
  justify-content: center !important;
}

.content {
  height: 100% !important;
  padding: 56px 0 0 !important;
  flex-grow: 1 !important;
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
}

.MuiDrawer-paper {
  top: 0;
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
}

.drawer.drawerClose {
  width: 70px !important;
  overflow-x: hidden !important;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
}

.drawerOpen1 {
  width: 260px !important;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
}

.drawerClose2 {
  width: 70px !important;
  overflow-x: hidden !important;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
}

.headerCurrentUser {
  display: flex;
  align-items: center;
}

.headerCurrentUser h3 {
  margin-right: 12px !important;
  max-width: 200px !important;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.headerCurrentUser a {
  font-size: 0;
  text-decoration: none;
  color: aliceblue;
}

.noDisplay {
  display: flex;
  align-items: center;
}

.noDisplay h3 {
  margin-right: 12px !important;
  max-width: 200px !important;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.noDisplay a {
  font-size: 0;
  text-decoration: none;
  color: aliceblue;
}

.body {
  max-width: none !important;
  min-height: 100vh !important;
  max-height: 100vh !important;
  margin: 0 auto !important;
  padding: 10px !important;
}

.companyLogo {
  background-image: var(--companyLogo);
  width: var(--logoWidth);
  height: var(--logoHeight);
  background-repeat: no-repeat;
  background-size: contain;
}

.closeSidenavList {
  padding-right: 5px !important;
}

.closeSidenav {
  padding-top: 0 !important;
  padding-left: 0 !important;
}

.closeSidenavSettings {
  padding-left: 15px !important;
}

.openSidenavTrans {
  padding-left: 15px !important;
}

.openSidenavSettingsNested {
  padding-left: 30px !important;
}

.groupButton {
  display: flex;
  align-items: center;
}


.groupButton2 {
  display: flex;
  align-items: center;
}

.toolBarBody {
  flex-direction: row !important;
  justify-content: space-between !important;
}

@media only screen and (max-width: 768px) {
  .body {
    height: 100%;
  }

  .drawerClose2 {
    display: none;
  }
  
  .drawer.drawerClose {
    display: none;
  }

  .companyLogo {
    height: 30px;
  }
  
  .toolBarBody {
    flex-direction: column;
  }

  .groupButton2 {
    justify-content: end;
    width: 100%
  }

  .groupButton {
    width: 100%;
  }

  .menuButton {
    margin-right: 0 !important;
  }

  .body {
    position: absolute !important;
  }

  .noDisplay {
    display: none;
  }

}

@media (min-width: 600px) {
  .toolbar {
    min-height: 64px !important;
  }

  .content {
    padding-top: 64px !important;
  }
}

/* @media only screen and (max-width: 480px) and (min-width: 320px)  {
  
} */

/* @media only screen and (max-width: 768px) and (min-width: 481px)  {

} */


/* @media only screen and (max-width: 769px) and (min-width: 1024px)  {

} */

/* 320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV */