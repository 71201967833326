.button {
  float: right;
}

.textfield {
  margin: 10px 5px 0 0 !important;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.actionButton button {
  border-radius: 20px;
  margin-top: 20px;
}

.textfield label {
  text-transform: capitalize;
}

.groupButton {
  float: right;
  border-radius: 50%;
}
