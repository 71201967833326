.home {
  color: #fff;
}

.textFields {
  margin: 12px 0;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.table {
  min-width: 700px;
}

.appbar {
  background-color: #006064;
}

.cellBody {
  display: flex;
  justify-content: space-between;
}

.error {
  color: red !important;
  margin: 0 !important;
  margin-top: 5px !important;
}

.actionButton button {
  border-radius: 20px !important;
}

.title {
  flex-grow: 1;
  font-weight: initial;
  margin-left: 10px;
  text-transform: capitalize;
}

.head {
  display: flex;
}

.header {
  display: flex;
  align-items: center;
}

.itemMargin {
  margin-left: 2;
}

.cell {
  height: 20px;
  padding: 10px;
  align-items: center;
  margin: 15px;
  text-transform: capitalize;
}

.tableTitle {
  display: flex;
  justify-content: space-between;
}

.tileBody {
  display: flex;
  align-items: center;
}

thead tr th {
  border-top: 1px solid #236064;
  background-color: #f5f4f4 !important;
  border-bottom: 2px solid #9e9a9a !important;
}

.actionButtonHoverable {
  position: absolute;
  min-width: 65px;
  right: 10px !important;
  border-bottom: none !important;
  opacity: 0;
  z-index: 10;
}

.actionTransButtonHoverable {
  position: absolute;
  min-width: 65px;
  right: 10px !important;
  margin-top: -65px !important;
  border-bottom: none !important;
  opacity: 0;
  z-index: 10;
}

.actionTransButtonHoverable:hover {
  opacity: 1;
}

.tableBody tr:hover td.actionTransButtonHoverable {
  opacity: 1 !important;
  transition: opacity 1s !important;
}

.actionButtonHoverable:hover {
  opacity: 1;
}

.tableBody tr:hover td.actionButtonHoverable {
  opacity: 1 !important;
  transition: opacity 1s !important;
}
