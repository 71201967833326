.text-body .MuiListItemText-primary {
  font-size: 25px;
}

.client-avatar .MuiAvatar-root {
  width: 60px !important;
  height: 60px !important;
  margin-right: 10px;
}

.group-body div#group_id {
  padding: 10px;
}

.title {
  font-size: 30px;
  font-weight: 500;
  margin-left: 20px;
}

.customFieldtitle {
  font-size: 22px;
  font-weight: 500;
}

.avatarSize {
  width: 70px !important;
  height: 70px !important;
}

.listAvatarName {
  display: flex;
  align-items: baseline;
}

.name {
  font-size: 30px;
  margin-left: 20px;
}

.iconSize {
  width: 50px !important;
  height: 50px !important;
}

.flex {
  display: flex;
}

.tabBody {
  border-bottom: 2px solid #ef8354;
  margin-right: 25px;
}

.tabBody .tabList {
  text-transform: none !important;
  min-width: 100px;
  flex-direction: initial !important;
}

.tabListTitle {
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
}

.PrivateTabIndicator-colorSecondary-14 {
  background-color: #0a6065;
}

.paper {
  color: rgba(0, 0, 0, 0.54);
  padding: 16px;
  text-align: center;
}

.taskBody {
  padding: 0;
}

.rightlistBody {
  padding: 20px;
  background: #f1f1f1;
  box-shadow: -4px 6px 6px -6px black;
}

.clientDetailsBody {
  margin-top: 20px;
  padding: 20px 10px 15px 50px;
}

.clientDetailsBody svg {
  margin-right: 10px;
  font-size: 30px;
  color: #004c50e6;
}

.subName {
  font-size: 15px;
  color: #2c4f50de;
  font-style: italic;
}

.clientDetailsValue {
  margin: 10px 10px 10px 40px;
  font-size: 17px;
}

.fieldClientTitle {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: flex-end;
}

.setLabelWidth {
  width: 150px;
}

.parentLabel {
  font-size: 20px;
  display: flex;
}

.parentLabel .MuiSvgIcon-root {
  width: 34px;
  height: 36px;
  margin-right: 15px;
}

.address-body {
  margin-bottom: 25px;
}

.contactList {
  padding-left: 30px;
  margin: 6px;
  width: 100%;
  display: flex;
}

.contact-body {
  margin-bottom: 25px;
}

.email-body {
  margin-bottom: 25px;
}

.emailBody button {
  margin-left: 85px !important;
}

.contactPersonName {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.parentName {
  font-size: 18px;
  font-weight: 600;
  color: #828181;
  border-top: 1px solid;
  border-bottom: 1px solid;
  padding-left: 4px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.parentAction {
  width: 85px;
}

.contactPersonAddMail {
  padding: 15px;
}

.contactPersonAddMail .label {
  width: 300px;
}

.addressList {
  padding-left: 60px !important;
  margin: 6px;
}

.addressList p {
  text-transform: capitalize;
}

.customFieldList {
  padding-left: 40px;
  margin: 6px;
}

.emailList {
  padding-left: 45px;
  margin: 6px;
  display: flex;
}

.mRT {
  margin-top: 20px;
}

.contactListLabel {
  text-transform: capitalize;
  font-size: 17px;
  width: 100px;
}

.emailListLabel {
  text-transform: capitalize;
  font-size: 17px;
}

.emailListValue {
  font-size: 17px;
}

.root {
  width: 275px;
}

.personBody {
  display: flex;
  justify-content: space-between;
}

.NameDatails {
  margin-left: 25px;
}

.contactAvatarSize {
  margin: 0 auto;
  width: 70px !important;
  height: 70px !important;
}

.contactTitle {
  display: flex;
  font-size: 30px;
  font-weight: 500;
  margin-top: 5px;
  align-items: center;
}

.contactTitle svg {
  margin-right: 10px;
}

.contactNumber {
  display: flex;
  justify-content: flex-end;
}

.contactNumberBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.emailBody {
  display: flex;
  justify-content: flex-end;
}

.flexJustify {
  display: flex;
  justify-content: space-between;
}

.Parent {
  width: 100%;
  margin-right: 70px;
}

.Parent .contactNumber {
  font-size: 17px;
  font-weight: 600;
}

.Parent .emailBody {
  font-size: 17px;
  font-weight: 600;
}

.tesss .Parent {
  margin-left: 22px;
}

button.expandedButton[aria-expanded='true'] {
  transform: scale(1.3) rotate(180deg);
  transition-delay: revert;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.actionButton button {
  border-radius: 20px !important;
}

.parentTitle {
  font-size: 22px !important;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
}

.parentTitle svg {
  margin-right: 10px;
}

.headerTitleBody {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  margin-bottom: 15px;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.headerTitleBody button {
  border-radius: 20px;
}

.error {
  color: #ff0404;
  margin-top: -2px;
  margin-bottom: 0;
  margin-left: 12px;
}

.clientDetails {
  display: flex;
  margin-left: 53px;
  font-size: 0.875rem;
}

.clientDetails div {
  margin: 0 10px;
}

.groupName {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.groupName svg {
  margin-right: 10px;
  color: #505d75;
}

.customFieldName {
  display: flex;
  align-items: center;
  margin-left: -4px;
}

.customFieldName svg {
  margin-right: 10px;
}

.customCell {
  border-bottom: none !important;
  width: 200px;
  border: none;
}

.customCellEmail {
  border-bottom: none !important;
  border: none;
}

.customCellValue {
  padding: 0 !important;
  border-bottom: none !important;
}

.buttonsHover {
  display: none;
  position: absolute;
  min-width: 65px;
  z-index: 1;
  right: 50px;
  margin-top: 5px;
}

.tableRowBody:hover {
  background: #dfdbdb;
}

.tableRowBody:hover .buttonsHover {
  display: block !important;
}

.buttonsHover a:hover {
  background-color: red !important;
}

.individualBody {
  display: flex;
  justify-content: space-between;
}

.contactLists {
  padding-left: 45px;
  margin: 6px;
  display: flex;
}

.buttonshoverAddress {
  display: flex;
  justify-content: space-between;
}

.seeAddress {
  margin-top: 10px;
}

.addressSeeBody:hover .seeAddress {
  color: red;
}

.addressContent {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.addressSeeBody:hover .addressContent {
  display: block;
}

.noAddress {
  margin-left: 58px;
}

.buttonshoverAddress button {
  margin: 0 5px;
}

.tabListTitle svg {
  margin-right: 10px;
  color: #4f5d75;
}

.customFieldBody {
  margin-top: 15px;
}

.emailListBody {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.contactListBody {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.contactCell {
  width: 200px;
}

.contactCell .Parent {
  margin: 0 !important;
  width: 100%;
}

.contactCell li {
  margin-left: 24px;
}

.customValue {
  display: flex;
}

.customLabelFied {
  width: 170px;
  font-weight: 600;
}

.customValueFied {
  width: 100%;
}

.searchButton {
  border-radius: 20px !important;
  margin: 0 10px;
  float: right;
}

.cellWidth {
  width: 150px !important;
}

.emailCell {
  overflow-wrap: anywhere;
}

.emailCellWidth {
  padding: 0 !important;
}

.boxMain {
  height: 100% !important;
  flex-grow: 1 !important;
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
  gap: 10px;
}

@media only screen and (max-width: 600px) and (min-width: 320px)  { 
  .addressList {
    padding-left: 0 !important;
  }

  .clientDetails {
    margin-left: 0;
  }

  .contactLists {
    padding-left: 0;
  }
  
  .emailList {
    padding-left: 0;
  }

  .emailListBody {
    align-items: baseline;
  }


}