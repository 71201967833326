:root {
  --headerColor: #2d3142;
  --appTitleColor: #67abbf;
  --iconColor: #222;
  --buttonColor: #222;
  --fontColor: #4f5d75;
  --blendColor: #df825f;
  --companyLogo: url('../assets/brand/lexLogo.png');
  --logoWidth: 200px;
  --logoHeight: 33px;
  --logoML: 15px;
}