.app {
  color: #fff;
}

.MuiList-root svg.MuiSvgIcon-root {
  color: #4f5d75;
}

.MuiPickersToolbar-toolbar {
  background-color: #df825f !important;
}

.MuiPickersDay-daySelected {
  background-color: #df825f !important;
}

.table-header-global {
  display: flex;
  justify-content: space-between;
  background-color: rgb(35, 96, 100);
}

.action-add-global {
  color: #fff !important;
}

.dialog-title {
  background-color: #2d3142;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

button {
  text-transform: capitalize !important;
}

.dialog-title h2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.dialog-title h2 button {
  color: #fff;
  font-weight: 800;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-align-baseline {
  display: flex;
  align-items: baseline;
}

.flex-align-flex-end {
  display: flex;
  align-items: flex-end;
}

.flex-align-justify {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-justify {
  display: flex;
  justify-content: space-between;
}

.flex {
  display: flex !important;
}

.toolbar-header-color {
  display: flex;
  justify-content: space-between;
  background-color: rgb(35 96 100);
}

.icon-10px {
  width: 10px !important;
}

.icon-20px {
  width: 20px !important;
}

.icon-30px {
  width: 30px !important;
}

.icon-40px {
  width: 40px !important;
}

.padding-none {
  padding: 0;
}

.m-r-10 {
  margin-right: 10px;
}

.iconWhite {
  color: #fff;
}

.customClassName table thead tr th {
  background-color: #9dcccf !important;
}

.classTransactions tbody tr td {
  padding: 5px 5px !important;
}

.classTransactions table thead tr th {
  padding: 5px 5px !important;
}

.MuiTableContainer-root.invoiceShowHeader table thead tr th {
  background-color: #74bfc3 !important;
}

.dialog-title .actionButton button {
  border-radius: 20px !important;
}

.MuiDialog-paper .actionSearchButton button {
  border-radius: 20px !important;
}

.ParentBody .MuiAccordionSummary-content {
  margin: 0 !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: fit-content !important;
}

.Folderlist div {
  border: none !important;
  background: none !important;
  box-shadow: none;
}

.MuiDialog-paper .headerTitleBody button {
  border-radius: 20px;
}

.clientDetailsValue .ContactEmail button {
  display: none !important;
}

.ContactEmail .contactPersonAddMail {
  text-align: left !important;
}

.contactPersonName {
  font-size: 20px;
  font-weight: 600;
}

.ContactEmail svg {
  display: none !important;
}

.legalcaseBody .ContactEmail {
  margin-left: 25px;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: #ef8354 !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #ef8354 !important;
}

#root h5 {
  color: #2d3142;
}

.sessionBody .loginButton button {
  background: #ef8345;
  color: #fff;
}

.loginButton .Mui-disabled {
  background-color: #cfcfcf !important;
}

.MuiTabs-indicator {
  background-color: #ef8345 !important;
}

.MuiBox-root {
  padding-top: 0 !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: rgba(255, 141, 89, 0.41) !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #ef8354 !important;
}

.contact-body {
  margin-top: 10px;
}

.email-body {
  margin-top: 10px;
}

.MuiButton-outlinedPrimary:hover {
  border: 1px solid #3f51b5;
  background-color: #7e91d34a !important;
}

.MuiTypography-body1 {
  font-size: 14px !important;
}

.active {
  background-color: #ffdfbc !important;
}

.MuiPickersBasePicker-container {
  align-items: center;
}

.MuiPickersToolbar-toolbar {
  width: 100%;
}

.MuiPickersStaticWrapper-staticWrapperRoot {
  min-width: 0 !important;
} 

.MuiPickersBasePicker-pickerView {
  min-width: 0 !important;
} 

.modalCtaContainer {
  display: flex;
  justify-content: flex-end;
  gap: 5px
}