.mainContainer {
  max-width: none !important;
  max-height: 1080px !important;
  height: 100% !important;
  background-color: #072853 !important
}

.mainContentContainer {
  height: 100%;
  padding: 0;
  display: flex !important;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.maintenanceTitle {
  font-size: 26px !important;
  font-weight: 600 !important;
  color: #fff;
  text-align: center;
}

.maintenanceSubtitle {
  font-size: 18px !important;
  font-weight: 100 !important;
  color: #fff;
  text-align: center;
  opacity: .7;
}

.maintenanceImage {
  width: 200px;
  height: 200px;
}

@media (min-width: 768px) {
  .maintenanceTitle {
    font-size: 38px !important;
    font-weight: 600 !important;
    color: #fff;
    text-align: center;
  }

  .maintenanceSubtitle {
    font-size: 26px !important;
    font-weight: 100 !important;
    color: #fff;
    text-align: center;
    opacity: .7;
  }

  .maintenanceImage {
    width: 250px;
    height: 250px;
  }
}