.textFields {
  margin: 12px 0 !important;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.error {
  color: red !important;
  margin: 0 !important;
  margin-top: -8px !important;
}

.actionButton button {
  border-radius: 20px !important;
}

.boxMain {
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
  gap: 10px !important;
}

.boxTable {
  flex-grow: 1 !important;
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
}