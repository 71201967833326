.mainContainer {
  max-width: none !important;
  max-height: 1080px !important;
  height: 100% !important;
  background-color: #072853 !important
}

.errorContentContainer {
  height: 100%;
  padding: 0;
  display: grid !important;
  grid-template-columns: 1fr
}

.errorMessageContainer {
  place-self: end center;
}

.errorTitle {
  margin-bottom: 5px;
  font-size: 3.5rem;
  font-weight: 300; 
  letter-spacing: 5px;
  line-height: .8;
  color: #fff;
}

.errorSubtitle {
  font-size: 1.5rem;
  font-weight: 800;
  color: #fff;
}

.errorMessage {
  font-size: .8rem;
  color: #fff 
}

.errorImage {
  place-self: start center;
  width: 300px;
  height: 300px;
}

@media (min-width: 768px) {
  .errorContentContainer {
    display: grid !important;
    grid-template-columns: 1fr 1.2fr
  }

  .errorMessageContainer {
    place-self: center end;
  }

  .errorTitle {
    margin-bottom: 10px;
    font-size: 8rem;
  }
  
  .errorSubtitle {
    font-size: 2.75rem;
  }

  .errorMessage {
    font-size: 1rem;
  }

  .errorImage {
    place-self: end start;
    width: 100%;
    height: 70%;
  }
}