.home {
  color: #fff;
}

.textFields {
  margin: 12px 0 !important;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.actionButton button {
  border-radius: 20px;
}

.formBody {
  height: 200px !important;
  border: dashed !important;
  display: grid;
  margin-bottom: 20px;
  margin-top: 20px;
}

.fileError {
  color: red;
}

.boxMain {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}