.paper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar {
  margin: 8px;
}

.form {
  width: 100%;
  margin-top: 8px;
}

.submit {
  margin: 24px 0 16px;
}

.logo {
  font-size: 60px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.errorText {
  color: red;
}

.sessionBody {
  background-image: url("../../../assets/images/signin-background.png");
  height: 100vh;
  background-size: cover;
}

.sessionList {
  height: 100%;
}

.sessionBox {
  width: 400px;
  background: #ffffffe0;
  padding-block: 20px;
  padding-inline: 37px;
  border-radius: 10px;
  box-shadow: 0 2px 10px #000000a1;
}

.linkBody div {
  margin-bottom: 5px;
  margin-top: 15px;
}

.logoHeader {
  margin: 20px 30px;
  display: flex;
  align-items: center;
  font-size: 1.875rem;
}

.logoHeader img {
  width: 80px;
}

.logoTitle {
  margin-left: 10px;
  text-shadow: 2px 2px 0px #93a79b;
  color: #fff;
  font-weight: 600;
}

.toolbar {
  display: flex;
  height: 20px;
}

.toolbarLabel {
  flex-grow: 1;
}

.header {
  background-color: #095c75;
  color: #fff;
  padding-right: 0;
}

.secondary {
  font-size: 18px !important;
  font-weight: 900 !important;
  color: #fff !important;
  background: #095c75 !important;
  height: 27px !important;
  width: 29px !important;
  display: flex !important;
  align-items: center !important;
}

.secondary:hover {
  background-color: red !important;
}


/************ media queries *********/
@media only screen and (max-width: 600px) and (min-width: 320px)  {
  .sessionBox {
    width: 350px;
    margin: 0 auto;
  }

  .logoHeader {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
  }
}

@media (min-width: 768px) {
  .logoHeader {
    margin-inline: 50px;
  }

  .logoTitle {
    margin-left: 20px;
  }

  .logoHeader img {
    width: 90px;
  }
}