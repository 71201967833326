.dateRange {
  display: flex !important;
  width: 170px !important;
  justify-content: space-between;
}

.filterField {
  margin: 0 10px !important;
}

.bodyDaterage {
  display: flex;
}

.invoiceFormbodyTable tbody tr td {
  padding: 6px !important;
}

.filterbyBody {
  display: flex;
  align-items: center;
  margin: 18px 0;
}

.labelFilter {
  font-size: 17px;
  font-weight: bold;
  width: 415px;
}

.cell {
  height: 20px !important;
  padding: 10px;
  align-items: center;
  margin: 15px !important;
  text-transform: capitalize;
  white-space: nowrap;
  max-width: 200px !important;
  text-overflow: ellipsis !important;
  overflow: hidden;
}

.cellLast {
  width: 75px;
}

.toltaLabel {
  font-size: 17px !important;
  font-weight: bold !important;
  text-align: right !important;
}

.totalLabelGrand {
  font-size: 17px !important;
  font-weight: bold !important;
  text-align: right !important;
  color: #ff0500 !important;
}

.toltaVat {
  font-size: 17px !important;
  font-weight: bold !important;
  text-align: right !important;
}

.grandTotalBody tbody tr td.toltaVat {
  border-bottom: 2px solid !important;
}

.grandTotalBody tbody tr td.totalValueVat {
  border-bottom: 2px solid !important;
}

.totalValue {
  text-align: right !important;
  font-size: 16px !important;
}

.totalValueGrand {
  text-align: right !important;
  font-weight: bold !important;
  font-size: 18px !important;
}

.totalValueVat {
  text-align: right !important;
  font-size: 16px !important;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.actionButton button {
  border-radius: 20px;
}

.grandTotalBody {
  border-top: 2px solid;
  padding-top: 10px;
  margin-bottom: 20px;
}

.grandInvoiceBody {
  border-top: 2px solid;
  padding-top: 10px;
  margin-bottom: 20px;
}

.grandTotalBody tbody tr td.cell {
  border: none !important;
}

.grandTotalBody tbody tr td.celldesc {
  border: none !important;
}

.grandTotalBody tbody tr td.toltaLabel {
  border: none !important;
}

.grandTotalBody tbody tr td.totalLabelGrand {
  border: none !important;
}

.grandTotalBody tbody tr td.totalValueGrand {
  border-bottom: 2px solid;
}

.grandInvoiceBody .cell {
  border: none !important;
}

.grandInvoiceBody .toltaLabel {
  border: none !important;
}

.grandInvoiceBody .totalLabelGrand {
  border: none !important;
}

.grandInvoiceBody .totalValueGrand {
  border-bottom: 2px solid;
}

.grandInvoiceBody .toltaVat {
  border-bottom: 2px solid !important;
}

.grandInvoiceBody .totalValueVat {
  border-bottom: 2px solid !important;
}

.grandInvoiceBody .cellLast {
  width: 115px;
}

.celldesc {
  width: 450px;
  border: none !important;
}

hr {
  border-width: initial;
  margin-top: 21px;
}

.amount {
  height: 20px;
  padding: 10px;
  align-items: center;
  margin: 15px;
  text-transform: capitalize;
  text-align: right;
}

.amt {
  text-align: right !important;
}

.discount {
  text-align: right;
}

.desc {
  width: 400px;
  text-transform: capitalize;
}

.rebate {
  text-transform: capitalize;
  color: red;
}

.btnColor {
  background-color: #1a237e !important;
  color: white !important;
}

.nameCell {
  height: 20px;
  padding: 10px;
  align-items: center;
  margin: 15px;
  text-transform: capitalize;
  display: flex;
}

.total {
  color: #7f0000;
  font-weight: bold;
}

.alignComponents {
  display: flex;
  align-items: center;
}

.space {
  margin-left: 1em !important;
}

.spaces {
  margin-left: 4.5em !important;
  max-width: 15%;
  max-height: 16px !important;
}

.numberField input {
  text-align: right;
}

.numberCell {
  height: 20px;
  padding: 10px;
  align-items: center;
  margin: 15px;
  text-align: right !important;
  text-transform: capitalize;
}

.totalAmt {
  height: 20px;
  padding: 10px;
  align-items: center;
  margin: 15px;
  color: maroon !important;
  text-transform: capitalize;
  font-weight: bold;
  font-style: italic;
  text-align: right !important;
}

.totalAmtText {
  margin-right: 30px;
}

.totalLabels {
  height: 20px;
  padding: 10px;
  align-items: center;
  margin: 15px;
  color: maroon !important;
  text-transform: capitalize;
  font-weight: bold;
  font-style: italic;
}

.actionCell {
  width: 300px;
}

.grid {
  display: grid;
  grid-template-columns: 60% 40%;

  /* grid-gap: 10px; */
}

.description {
  display: flex;
  margin: 0 10px !important;
}

.description label {
  width: 150px;
  font-size: 16px;
}

.user {
  display: flex;
  margin: 10px 10px !important;
}

.user label {
  width: 150px;
  font-size: 16px;
}

.flex {
  display: flex;
}

.line {
  border-bottom: 3px solid;
  margin: 10px 0;
}

.amountflex {
  display: flex;
  justify-content: space-between;
}

.amountflex input {
  width: 338px;
}

.amountflex label {
  font-size: 18px;
}

.invoiceListright div {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.invoiceListright label {
  padding-right: 70px;
  font-size: 17px;
}

.invoiceListright div .invoiceListrightInput {
  width: 400px !important;
  margin: 5px 0;
}

.invoiceListLeft div {
  display: flex;
  align-items: baseline;
}

.invoiceListLeft label {
  width: 200px;
  font-size: 17px;
  margin: 5px 0;
}

.transactionAddbuttons {
  display: flex;
  justify-content: space-around;
}

.transactionExist span {
  display: block;
}

.transactionNew span {
  display: block;
}

.transactionNew svg {
  font-size: 86px;
  color: #1e4688;
}

.transactionExist svg {
  font-size: 86px;
  color: #748094;
}

.transactionExist:hover {
  background-color: initial;
}

.transactionNew:hover {
  background-color: initial;
}

.invoiceValue {
  width: 200px;
  font-size: 17px;
}

.groupHeaderButton {
  display: flex;
  width: 165px;
  justify-content: space-between;
  align-items: flex-end;
}

.headerTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.submitButton {
  float: right;
  border-radius: 20px !important;
  margin-bottom: 10px !important;
}

.mainBox {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
}